import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcInputScannerDirective } from './tc-input-scanner.directive';

@NgModule({
  declarations: [TcInputScannerDirective],
  imports: [CommonModule],
  exports: [TcInputScannerDirective],
})
export class TcInputScannerModule {}
