import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { LayoutComponent } from 'brinks';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

  // Auth routes for guests
  {
    path: '',
    //   canActivate: [NoAuthGuard],
    //   canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('libs/brinks/src/lib/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('libs/brinks/src/lib/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('libs/brinks/src/lib/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () => import('libs/brinks/src/lib/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    //        canActivate: [NoAuthGuard],
    //        canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('libs/brinks/src/lib/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'expositions',
        loadChildren: () => import('libs/brinks/src/lib/expositions/expositions.module').then((m) => m.ExpositionsModule),
      },
      {
        path: 'showcase',
        loadChildren: () => import('libs/brinks/src/lib/showcase/showcase.module').then((m) => m.ShowcaseModule),
      },
    ],
  },
];
