import { isDevMode, NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco.http-loader';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    [
      provideTransloco({
        config: {
          availableLangs: [
            {
              id: 'cs',
              label: 'Česky',
            },
            {
              id: 'en',
              label: 'English',
            },
            {
              id: 'de',
              label: 'Deutschland',
            },
          ],
          defaultLang: 'cs',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
        },
        loader: TranslocoHttpLoader,
      }),
    ],
  ],
})
export class TranslocoCoreModule {}
