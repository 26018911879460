/* tslint:disable:max-line-length */
import { FuseNavigationItem } from 'fuseui';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'expositions',
    title: 'Expozitury',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/expositions',
  },
  // {
  //   id: 'requests',
  //   title: 'Správa OTP',
  //   type: 'basic',
  //   icon: 'heroicons_outline:lock-closed',
  //   link: '/requests',
  // },
  {
    id: 'app-group',
    title: 'menu.app-group',
    subtitle: 'menu.app-group.subtitle',
    type: 'group',
    children: [
      // {
      //   id: 'users',
      //   title: 'menu.users',
      //   type: 'basic',
      //   icon: 'heroicons_outline:users',
      //   link: '/users',
      // },
      // {
      //   id: 'support',
      //   title: 'menu.support',
      //   type: 'basic',
      //   icon: 'heroicons_outline:question-mark-circle',
      //   link: '/support',
      // },
      // {
      //   id: 'changelog',
      //   title: 'menu.changelog',
      //   type: 'basic',
      //   icon: 'heroicons_outline:newspaper',
      //   link: '/changelog',
      // },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'expositions',
    title: 'Expozitury',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/expositions',
  },
  // {
  //   id: 'users',
  //   title: 'Přehled uživatelů',
  //   type: 'basic',
  //   icon: 'heroicons_outline:users',
  //   link: '/users',
  // },
  // {
  //   id: 'requests',
  //   title: 'Správa OTP',
  //   type: 'basic',
  //   icon: 'heroicons_outline:lock',
  //   link: '/requests',
  // },
  // {
  //     id      : 'misc-group',
  //     title   : 'Ostatní',
  //     type    : 'group',
  //     role    : 'other',
  //     children: [
  //         {
  //             id   : 'balance',
  //             title: 'Transakce',
  //             type : 'basic',
  //             icon : 'heroicons_outline:currency-dollar',
  //             link : '/balance'
  //         },
  //
  //     ]
  // },
  {
    id: 'app-group',
    title: 'Aplikace Brinks',
    subtitle: 'Vše co potřebujete vědět',
    type: 'group',
    children: [
      {
        id: 'support',
        title: 'Podpora',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/support',
      },
      {
        id: 'changelog',
        title: 'Changelog',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/changelog',
      },
    ],
  },
];
