import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: 'input[tcInputScanner]',
})
export class TcInputScannerDirective implements AfterViewInit {
  @Input() keepFocused = true;
  @Output() scan: EventEmitter<string> = new EventEmitter<string>();

  private inputElement!: HTMLInputElement;

  constructor(private readonly el: ElementRef) {
    if (!(el.nativeElement instanceof HTMLInputElement)) {
      throw new Error('tcInputScanner allowed on input element only!');
    }
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event.target.value', '$event']) onKeyDown(value: string, event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.inputElement.disabled = true;
      this.scan.emit(value);
    }
  }

  ngAfterViewInit(): void {
    this.inputElement.focus();
    interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (this.keepFocused && !this.inputElement.disabled) {
            this.inputElement.focus();
          }
        },
      });
  }

  public reset(): void {
    this.inputElement.value = '';
    this.inputElement.disabled = false;
    this.inputElement.focus();
  }

  public submit(): void {
    this.inputElement.disabled = true;
    this.scan.emit(this.inputElement.value);
  }
}
