import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, NoPreloading, RouterModule } from '@angular/router';
import { FuseConfigModule, FuseMockApiModule, FuseModule } from 'fuseui';
import { appConfig } from '../../../../libs/brinks/src/lib/config/app.config';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { BrinksModule } from '../../../../libs/brinks/src/lib/brinks.module';
import { CoreModule } from './core/core.module';
import { LayoutModule, UnauthorizedInterceptor } from 'brinks';
import { mockApiServices } from './mock-api';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const routerConfig: ExtraOptions = {
  preloadingStrategy: NoPreloading,
  scrollPositionRestoration: 'enabled',
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    BrinksModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    // Core module of your application
    CoreModule,
    // Layout module of your application
    LayoutModule,

    // Translate module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [UnauthorizedInterceptor],
  bootstrap: [AppComponent],
})
export class AppModule {}
