<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
  <div
    class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
    <div class="w-full max-w-140 sm:w-140 mx-auto sm:mx-0">
      <!-- Logo -->
      <div class="w-12">
        <img src="assets/images/logo/logo-180.png" />
      </div>

      <!-- Title -->
      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Registrace</div>
      <div class="flex items-baseline mt-0.5 font-medium">
        <div>Již máte vytvořený účet?</div>
        <a class="ml-1 text-primary-500 hover:underline" [routerLink]="['/sign-in']">Přihlášení </a>
      </div>
      <!-- Alert -->
      <fuse-alert
        class="mt-8 -mb-4"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'">
        {{ alert.message }}
      </fuse-alert>

      <!-- Sign Up form -->
      <form class="mt-8" [formGroup]="signUpForm">
        <div class="flex">
          <div class="flex-1 m-3">
            <div class="mb-5 text-2xl font-extrabold tracking-tight leading-tight">Partner</div>
            <mat-form-field class="w-full">
              <mat-label>Název</mat-label>
              <input id="partner-name" matInput [formControlName]="'partner_name'" />
              <mat-error *ngIf="signUpForm.get('partner_name').hasError('required')"> Vyplňte prosím název </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Popis</mat-label>
              <input id="partner-description" matInput [formControlName]="'description'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Země</mat-label>
              <input id="partner-addressCountry" matInput [formControlName]="'partner_addressCountry'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Město</mat-label>
              <input id="partner-addressCity" matInput [formControlName]="'partner_addressCity'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Ulice</mat-label>
              <input id="partner-addressStreet" matInput [formControlName]="'partner_addressStreet'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>PSČ</mat-label>
              <input id="partner-addressPost" matInput [formControlName]="'partner_addressPost'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>IČO</mat-label>
              <input id="partner-identificationNumber" matInput [formControlName]="'partner_identificationNumber'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>DIČ</mat-label>
              <input id="partner-vatNumber" matInput [formControlName]="'partner_vatNumber'" />
            </mat-form-field>
          </div>

          <div class="flex-1 m-3">
            <div class="mb-5 text-2xl font-extrabold tracking-tight leading-tight">Uživatel</div>

            <mat-form-field class="w-full">
              <mat-label>Jméno</mat-label>
              <input id="user-firstname" matInput [formControlName]="'user_firstname'" />
              <mat-error *ngIf="signUpForm.get('user_firstname').hasError('required')"> Vyplňte prosím jméno </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Přijmení</mat-label>
              <input id="user-lastname" matInput [formControlName]="'user_lastname'" />
              <mat-error *ngIf="signUpForm.get('user_lastname').hasError('required')"> Vyplňte prosím přijmení </mat-error>
            </mat-form-field>

            <!-- Email field -->
            <mat-form-field class="w-full">
              <mat-label>Přihlašovací jméno</mat-label>
              <input id="user-email" matInput [formControlName]="'user_email'" />
              <mat-error *ngIf="signUpForm.get('user_email').hasError('required')"> Vyplňte prosím email </mat-error>
              <mat-error *ngIf="signUpForm.get('user_email').hasError('email')"> Vyplňte prosím platnou emailovou adresu </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Telefon</mat-label>
              <input id="user-phone" matInput [formControlName]="'user_phone'" />
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Datum narození</mat-label>
              <i class="mr-2 hidden sm:flex icon-size-5 fas fa-birthday-cake"></i>
              <input
                matInput
                [matDatepicker]="birthdayDatepicker"
                [formControlName]="'birthDate'"
                [(ngModel)]="viewDate"
                [placeholder]="'Datum narození'" />
              <mat-datepicker-toggle matSuffix [for]="birthdayDatepicker"> </mat-datepicker-toggle>
              <mat-datepicker #birthdayDatepicker></mat-datepicker>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full">
              <mat-label>Heslo</mat-label>
              <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
              <button
                mat-icon-button
                type="button"
                (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
                matSuffix>
                <i *ngIf="passwordField.type === 'password'" class="far fa-eye"></i>
                <i *ngIf="passwordField.type === 'text'" class="far fa-eye-slash"></i>
              </button>
              <mat-error> Vyplňte prosím heslo </mat-error>
            </mat-form-field>

            <div class="grid grid-cols-1 gap-6 w-full mt-8">
              <!-- Communication -->
              <div class="flex items-center justify-between">
                <div class="flex-auto cursor-pointer" (click)="communication.toggle()">
                  <div class="leading-6 font-medium flex flex-row"><i class="mr-3 hidden sm:flex icon-size-5 fas fa-lock"></i>GDPR</div>
                  <div class="text-md text-secondary">Souhlas se zpracováním údajů.</div>
                </div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'gdprAgree'" #communication> </mat-slide-toggle>
              </div>
              <!-- Security -->
              <div class="flex items-center justify-between">
                <div class="flex-auto cursor-pointer" (click)="securityToggle.toggle()">
                  <div class="leading-6 font-medium flex flex-row">
                    <i class="mr-3 hidden sm:flex icon-size-5 fas fa-comments"></i>Komentáře
                  </div>
                  <div class="text-md text-secondary">Povolit komentáře tomuto profilu.</div>
                </div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'commentsAgree'" #securityToggle> </mat-slide-toggle>
              </div>
              <!-- Meetups -->
              <div class="flex items-center justify-between">
                <div class="flex-auto cursor-pointer" (click)="meetupsToggle.toggle()">
                  <div class="leading-6 font-medium flex flex-row">
                    <i class="mr-3 hidden sm:flex icon-size-5 fas fa-newspaper"></i>Novinky
                  </div>
                  <div class="text-md text-secondary">Povolit zasílání novinek.</div>
                </div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'newsAgree'" #meetupsToggle> </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>

        <!-- ToS and PP -->
        <div class="inline-flex items-end w-full mt-2">
          <mat-checkbox [color]="'primary'" [formControlName]="'agreements'">
            <span>I agree to the</span>
            <a class="ml-1 text-primary-500 hover:underline" [routerLink]="['./']">Terms of Service </a>
            <span>and</span>
            <a class="ml-1 text-primary-500 hover:underline" [routerLink]="['./']">Privacy Policy </a>
          </mat-checkbox>
        </div>

        <!-- Submit button -->
        <button
          class="fuse-mat-button-large w-60 mt-6"
          mat-flat-button
          [color]="'primary'"
          [disabled]="signUpForm.disabled"
          (click)="signUp()">
          <span *ngIf="!signUpForm.disabled"> Create your free account </span>
          <mat-progress-spinner *ngIf="signUpForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
      </form>
    </div>
  </div>
  <div
    class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-red dark:border-l">
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg
      class="absolute inset-0 pointer-events-none"
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMax slice"
      xmlns="http://www.w3.org/2000/svg">
      <g class="color-white opacity-25" fill="none" stroke="currentColor" stroke-width="100">
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <!-- Dots -->
    <svg class="absolute -top-16 -right-16 color-white" viewBox="0 0 220 192" width="220" height="192" fill="none">
      <defs>
        <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
    </svg>
    <!-- @formatter:on -->
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>portalveritele</div>
      </div>
      <div class="mt-6 text-lg tracking-tight leading-6 text-white">Amazing places, for amazing moments</div>
      <div class="flex items-center mt-8">
        <div class="flex flex-0 items-center -space-x-1.5">
          <img
            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-red ring-offset-gray-800 object-cover"
            src="assets/images/avatars/female-18.jpg" />
          <img
            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-red ring-offset-gray-800 object-cover"
            src="assets/images/avatars/female-11.jpg" />
          <img
            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-red ring-offset-gray-800 object-cover"
            src="assets/images/avatars/male-09.jpg" />
          <img
            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-red ring-offset-gray-800 object-cover"
            src="assets/images/avatars/male-16.jpg" />
        </div>
        <div class="ml-4 font-medium tracking-tight text-white">More than 17k people joined us, it's your turn</div>
      </div>
    </div>
  </div>
</div>
