import { NgModule } from '@angular/core';
import { ApiModule, AuthModule, BASE_PATH } from 'brinks';
import { TcUtilsModule } from 'tcdesign';
import { environment } from '../environments/environment';

@NgModule({
  imports: [AuthModule, ApiModule, TcUtilsModule],
  providers: [{ provide: BASE_PATH, useValue: environment.apiUrl }],
})
export class BrinksModule {}
