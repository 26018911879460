import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { AdministratorService } from 'brinks';

@Injectable({
  providedIn: 'root',
})
export class GlobalParamsService {
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient, // private readonly _adminService: AdministratorService
  ) {}
}
