/**
 * AlbertApiProject
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AccessRequest } from '../model/models';
import { AccessResource } from '../model/models';
import { LogoutResource } from '../model/models';
import { UserResource } from '../model/models';

import { Configuration } from '../configuration';

export interface AccessServiceInterface {
  defaultHeaders: HttpHeaders;
  configuration: Configuration;

  /**
   * Logged user
   *
   */
  loggedUser(extraHttpRequestParams?: any): Observable<UserResource>;

  /**
   * Login User
   *
   * @param accessRequest
   */
  loginUser(accessRequest?: AccessRequest, extraHttpRequestParams?: any): Observable<AccessResource>;

  /**
   * Logout user
   *
   */
  logoutUser(extraHttpRequestParams?: any): Observable<LogoutResource>;
}
