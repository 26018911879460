import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'error-page-flow',
  templateUrl: 'error-page-flow.component.html',
  styleUrls: ['error-page-flow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorPageFlowComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
