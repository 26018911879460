<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
  <div
    class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
      <!-- Logo -->
      <div class="w-50">
        <img src="./assets/images/logo/logo-180.png" />
      </div>

      <!-- Title -->
      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-primary">Přihlášení</div>
      <!-- <div class="flex items-baseline mt-0.5 font-medium">
                <div>Vytvořit nový účet:</div>
                <a
                    class="ml-1 text-primary-500 hover:underline"
                    [routerLink]="['/sign-up']">Registrace
                </a>
            </div> -->
      <!-- Alert -->
      <fuse-alert
        class="mt-8 -mb-4"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'">
        {{ alert.message }}
      </fuse-alert>

      <!-- Sign in form -->
      <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">
        <!-- Email field -->
        <mat-form-field class="w-full">
          <mat-label>Přihlašovací jméno</mat-label>
          <input id="email" matInput [formControlName]="'email'" />
          <mat-error *ngIf="signInForm.get('email').hasError('required')"> Vyplňte prosím email </mat-error>
          <mat-error *ngIf="signInForm.get('email').hasError('email')"> Vyplňte prosím platnou emailovou adresu </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="w-full">
          <mat-label>Heslo</mat-label>
          <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
          <button
            mat-icon-button
            type="button"
            (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
            matSuffix>
            <i *ngIf="passwordField.type === 'password'" class="far fa-eye"></i>
            <i *ngIf="passwordField.type === 'text'" class="far fa-eye-slash"></i>
          </button>
          <mat-error> Vyplňte prosím heslo </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <!-- <div class="inline-flex items-end justify-between w-full mt-1.5">
                    <mat-checkbox
                        disabled
                        [color]="'primary'"
                        [formControlName]="'rememberMe'">
                        Zapamatovat přihlášení
                    </mat-checkbox>

                    <a
                        class="text-md font-medium text-primary-500 hover:underline"
                        [routerLink]="['/forgot-password']">Forgot password?
                    </a>

                </div> -->

        <!-- Submit button -->
        <button
          class="fuse-mat-button-large w-full mt-6 bg-primary"
          mat-flat-button
          [color]="'primary'"
          [disabled]="signInForm.disabled"
          (click)="signIn()">
          <span *ngIf="!signInForm.disabled"> Přihlásit </span>
          <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
        <!-- <div class="items-center justify-center text-center">
                    <span><a class="font-medium text-primary-500 hover:underline" href="/forgot-password">Zapoměli jste heslo?</a></span>
                </div> -->
      </form>
    </div>
  </div>
  <div
    class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-primary dark:border-l">
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg
      class="absolute inset-0 pointer-events-none"
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMax slice"
      xmlns="http://www.w3.org/2000/svg">
      <g class="color-white opacity-25" fill="none" stroke="currentColor" stroke-width="100">
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <!-- Dots -->
    <svg class="absolute -top-16 -right-16 color-white" viewBox="0 0 220 192" width="220" height="192" fill="none">
      <defs>
        <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
    </svg>
    <!-- @formatter:on -->
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>Albert</div>
      </div>
    </div>
  </div>
</div>
