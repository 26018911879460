import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { UserService } from 'brinks';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _httpClient: HttpClient) {}
}
