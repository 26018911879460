import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from 'fuseui';
import { EmptyLayoutComponent } from './empty.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EmptyLayoutComponent],
  imports: [RouterModule, FuseLoadingBarModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [EmptyLayoutComponent],
})
export class EmptyLayoutModule {}
