import { MatDialog } from '@angular/material/dialog';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorPageFlowComponent } from '../pages/error-page-flow/error-page-flow.component';

export interface ErrorConfig<D = any> {
  dialogData?: D | undefined;
  title?: string;
  message?: string;
  error?: D | undefined;
}

export function customFlowErrorHandler<T>(
  dialog: MatDialog,
  router: Router,
  config: ErrorConfig,
): (source$: Observable<T>) => Observable<T> {
  return (source$) =>
    source$.pipe(
      catchError((err) => {
        const dialogRef = dialog.open(ErrorPageFlowComponent, {
          height: 'calc(100%)',
          width: 'calc(100%)',
          maxWidth: '100%',
          maxHeight: '100%',
          data: config.dialogData,
          panelClass: 'custom-container',
        });
        dialogRef.afterClosed().subscribe({
          next: () => {
            router.navigate(['/dashboard']);
          },
        });
        return throwError(() => err);
      }),
    );
}

export function customDialogErrorHandler<T>(
  dialog: MatDialog,
  router: Router,
  config: ErrorConfig,
): (source$: Observable<T>) => Observable<T> {
  return (source$) =>
    source$.pipe(
      catchError((err) => {
        config.error = err;
        const dialogRef = dialog.open(ErrorPageFlowComponent, {
          data: config,
        });

        return throwError(() => err);
      }),
    );
}

export function customRouterErrorHandler<T>(router: Router): (source$: Observable<T>) => Observable<T> {
  return (source$) =>
    source$.pipe(
      catchError((err) => {
        router.navigate(['error']);
        return throwError(() => err);
      }),
    );
}
