import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { AccessService } from 'brinks';

@Injectable()
export class AuthService {
  private _authenticated = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _accessService: AccessService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    // @ts-ignore
    return localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== 'undefined'
      ? localStorage.getItem('accessToken')
      : '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    // @ts-ignore
    const credential: AuthCredential = {
      login: credentials.email,
      password: credentials.password,
    };

    return this._accessService.loginUser(credential).pipe(
      switchMap((response) => {
        // Store the access token in the local storage
        // @ts-ignore
        this.accessToken = response.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        // this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Refresh token
   */
  tryToRefreshToken(): Observable<any> {
    return this._httpClient
      .post('/api/auth/refresh-token', {
        refreshToken: this.refreshToken,
      })
      .pipe(
        catchError(() => {
          // Return false @todo: Delete local storage and setup _authenticated
          this._authenticated = false;
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          return of(false);
        }),
        switchMap((response: any) => {
          if (response === false) {
            this._authenticated = false;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return of(false);
          } else {
            this.accessToken = response.accessToken;
            this.refreshToken = response.refreshToken;
            return of(true);
          }
        }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this._accessService.logoutUser().subscribe(() => {
      // Remove the access token from the local storage
      localStorage.removeItem('accessToken');

      // Set the authenticated flag to false
      this._authenticated = false;
    });

    // Return the observable
    return of(true);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return this.tryToRefreshToken();
    }

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }

  signout(): Observable<any> {
    return this._httpClient.delete('api/auth/logout');
  }
}
