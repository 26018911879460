import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'brinks';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const errorCodes = [401, 403];

        if (errorCodes.includes(err.status)) {
          // auto logout if 401 or 403 response returned from api
          this._authService.signOut().subscribe(() => {
            this.router.navigate(['/sign-out']);
          });
        }
        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      }),
    );
  }
}
