import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { AccessService, AccessResource } from 'brinks';
import { UserResource } from '../../../../../../libs/brinks/src';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<UserResource> = new ReplaySubject<UserResource>(1);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private readonly _accessService: AccessService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: AccessResource) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<AccessResource> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<UserResource> {
    return this._accessService.loggedUser().pipe(
      tap((user) => {
        console.log(user);
        this._user.next(user);
      }),
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: AccessResource): Observable<any> {
    return this._httpClient.patch<AccessResource>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }
}
