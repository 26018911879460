import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserService } from '../../../../../../../apps/fuse/src/app/core/user/user.service';

import { AuthService } from '../../../auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessResource } from 'brinks';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar = true;
  user?: AccessResource;
  total!: number | undefined;

  notificationSubscription!: Subscription;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _notificationService: NotificationService,
    private _authService: AuthService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$.pipe(untilDestroyed(this)).subscribe((user: AccessResource) => {
      this.user = user;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    this._authService.signOut().subscribe(() => {
      this._router.navigate(['/sign-out']);
    });
  }

  userProfile(): void {
    this._router.navigate(['/users/profile']);
  }

  onNotificationsClose(): void {
    //
  }
}
