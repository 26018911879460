/**
 * AlbertApiProject
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ExpositureResource } from '../model/models';
import { ServiceResource } from '../model/models';

import { Configuration } from '../configuration';

export interface ExpositureServiceInterface {
  defaultHeaders: HttpHeaders;
  configuration: Configuration;

  /**
   * Info about expositure included last requests
   *
   * @param expositureId
   */
  expositureLastRequests(expositureId: number, extraHttpRequestParams?: any): Observable<ExpositureResource>;

  /**
   * List expositures
   *
   * @param searchText
   */
  expositureList(searchText?: string, extraHttpRequestParams?: any): Observable<Array<ExpositureResource>>;

  /**
   * Info about request included codes
   *
   * @param heliosOrgId
   * @param date
   */
  requestCodes(heliosOrgId: number, date: string, extraHttpRequestParams?: any): Observable<ServiceResource>;
}
