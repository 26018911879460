<div class="flex items-center">
  <!-- Button -->
  <button class="flex flex-row items-center" [matMenuTriggerFor]="userActions">
    <div class="mr-2">{{ user.userName }}</div>

    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </button>

  <button class="ml-4 mt-1" [matMenuTriggerFor]="notifications">
    <mat-icon matBadge="{{ total }}" matBadgeColor="warn" [svgIcon]="'feather:bell'"></mat-icon>
  </button>
</div>

<mat-menu
  class="min-w-96 p-0 notification-dropdown rounded-md"
  [xPosition]="'before'"
  #notifications="matMenu"
  (closed)="onNotificationsClose()">
  <div class="text-primary bg-white border-b border-primary h-10 flex items-center justify-center">
    {{ 'notifications.dropdown.title' | translate }}
  </div>

  <div *ngFor="let message of notifications.messages">
    <div class="flex flex-col hover:bg-blue-50">
      <div class="border-b p-4 flex items-center">
        <span class="p-2 rounded-full h-10 w-10 bg-orange-600 mr-4"
          ><mat-icon class="text-white" [svgIcon]="'feather:bell'"></mat-icon
        ></span>
        <div class="flex flex-col justify-center">
          <span>{{ message.title }}</span>
          <span class="text-sm">{{ message.message }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col hover:bg-blue-50">
      <div class="border-b p-4 flex items-center">
        <span class="p-2 rounded-full h-10 w-10 bg-slate-400 mr-4"
          ><mat-icon class="text-white" [svgIcon]="'feather:bell'"></mat-icon
        ></span>
        <div class="flex flex-col justify-center text-slate-400">
          <span>Title</span>
          <span class="text-sm">subtitle</span>
        </div>
      </div>
    </div>
  </div>
</mat-menu>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <div mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Přihlášen jako</span>
      <span class="mt-1.5 text-md font-medium">
        <strong>{{ user.userName }}</strong>
      </span>
    </span>
  </div>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="userProfile()">
    <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
    <span>Profil</span>
  </button>
  <!-- <button mat-menu-item
            (click)="settings()">
        <i class="far mr-2 fa-cog"></i>
        <span>Nastavení</span>
    </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <i class="far mr-2 fa-sign-out-alt"></i>
    <span>Odhlásit se</span>
  </button>
</mat-menu>
