/**
 * AlbertApiProject
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceResource } from './serviceResource';

export interface ExpositureResource {
  id?: number;
  name?: string;
  address?: string | null;
  code?: string | null;
  orgId?: number | null;
  readyCount?: number | null;
  readyLastDate?: string | null;
  readyReadDate?: string | null;
  notReadyCount?: number | null;
  notReadyFirstDate?: string | null;
  notReadyLastDate?: string | null;
  services?: Array<ServiceResource>;
}
