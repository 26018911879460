import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'tc-paginator',
  templateUrl: './tc-paginator.component.html',
  styleUrls: ['./tc-paginator.component.css'],
})
export class TcPaginatorComponent {
  @Input() length?: number;
  @Input() type?: string = 'basic';
  @Input() pageSize?: number = 30;
  @Output() onClick = new EventEmitter<any>();

  pageCount: number;
  current: number;
  previous?: number;
  next?: number;

  ngOnInit() {
    this.pageCount = Math.ceil(this.length / this.pageSize);
    this.current = 0;
    this.previous = undefined;
    this.next = 1;
  }

  output(page) {
    this.current = page;

    if (page !== 0) {
      this.previous = page - 1;
    } else {
      this.previous = undefined;
    }

    if (page !== this.pageCount - 1) {
      this.next = page + 1;
    } else {
      this.next = undefined;
    }

    this.onClick.emit(page);
  }

  ngOnChanges() {
    this.pageCount = Math.ceil(this.length / this.pageSize);
    this.current = 0;
    this.previous = undefined;
    this.next = 1;
  }
}
