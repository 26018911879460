<div class="flex flex-row items-center justify-center h-full p-left">
  <img class="w-1/2" src="assets/images/error.png" />

  <div class="p-box">
    <div class="text-8xl font-bold mb-8">Chyba!</div>
    <div class="text-3xl mb-8">
      {{ data.error?.error?.statusDescription ? (data.error?.error?.statusDescription | translate) : 'Chyba!' }}
    </div>
    <button mat-button mat-dialog-close class="bg-primary text-white rounded-full hover:bg-blue-50 ease-in-out duration-300 mr-2">
      Zavřít
    </button>
  </div>
  <mat-dialog-actions class="grow justify-center items-end"> </mat-dialog-actions>
</div>
