import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageFlowComponent } from './pages/error-page-flow/error-page-flow.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ErrorPageFlowComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [],
})
export class TcErrorModule {}
